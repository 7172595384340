<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <h4 v-if="missingDates">Липсващи графици за:</h4>
      <div
        class="flex d-inline-flex"
        v-if="missingDates"
        style="overflow-y: auto"
      >
        <div
          v-for="(storeIds, index) in missingDates"
          :key="index"
          class="pr-6 pb-3"
        >
          <div v-for="store in stores" :key="store.id" class="my-3 text-nowrap">
            <strong>{{ store.id == index ? store.name : "" }}</strong>
          </div>
          <div v-for="missingDate in storeIds" class="mb-2">
            <a href="#" @click="missingDateSearch(index, missingDate)">{{
              missingDate | formatDate
            }}</a>
          </div>
        </div>
      </div>
      <template v-slot:header>
        <div class="card-title">
          {{ $t("USERS_SCHEDULE") + ": " + storeName }}
        </div>

        <div class="card-toolbar">
          <v-dialog v-model="dialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
                @click="getUsersScheduleList()"
              >
                <i class="flaticon2-plus"></i>
                {{ $t("ADD_EDIT") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{
                  $t("USERS_SCHEDULE") + " - " + storeName + " - " + workDayDate
                }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <CheckboxList
                        :heading="$t('EMPLOYEES')"
                        :items="users"
                        :selectedItems.sync="filters.users"
                        :modelName="'users'"
                        :firstLoader="firstLoader"
                        :checkboxLoader="isLoadingUsersSchedule"
                        :labelKey="'name'"
                      >
                      </CheckboxList>
                    </v-col>
                    <v-col cols="12" sm="6" md="4"> </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save()"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-progress-circular
            v-show="isPDFloading"
            indeterminate
            color="green"
          ></v-progress-circular>
          <v-btn
            v-show="!isPDFloading"
            color="btn-success"
            class="btn btn-light-success font-weight-bolder mr-2 mt-3"
            @click="getPDF"
          >
            <i class="flaticon-download"></i>
            {{ $t("DNEVNIK_LHP") }}
          </v-btn>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :sort-desc="false"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingUsersSchedule"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="users-schedule-table"
        >
          <v-divider inset></v-divider>
          <template v-slot:top>
            <SearchTools
              :searchQuery.sync="searchQuery"
              @do-search="doSearch"
              @do-clear="doClear"
            >
              <template v-slot:prepend>
                <b-col>
                  <FormDatepickerBase
                    :type="'text'"
                    :clearable="true"
                    :i18n="{
                      label: 'USERS_SCHEDULE_DATE'
                    }"
                    :date.sync="filters.workDay"
                  ></FormDatepickerBase>
                </b-col>
                <b-col>
                  <v-autocomplete
                    :items="stores"
                    :label="$t('STORE')"
                    item-text="name"
                    item-value="id"
                    hide-details
                    v-model="filters.storeId"
                    @click:clear="clearFilter('storeId')"
                  ></v-autocomplete>
                </b-col>
              </template>
            </SearchTools>
          </template>
          <template v-slot:item.first_name="{ item }">
            {{ item.first_name }}
          </template>

          <template v-slot:item.last_name="{ item }">
            {{ item.last_name }}
          </template>

          <template v-slot:item.stores="{ item }">
            {{ item.store }}
          </template>
          <template v-slot:item.work_day="{ item }">
            {{ item.work_day | formatDate }}
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>
<script>
import {
  FETCH_USERS_SCHEDULE,
  FETCH_USERS_SCHEDULE_LIST,
  FETCH_USERS_WITH_VALID_HC,
  FETCH_MISSING_SCHEDULE_DATES,
  SAVE_USERS_SCHEDULE,
  USERS_SCHEDULE_PDF
} from "@/modules/users-schedule/store/users-schedule.module";
//Fetch stores
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import { mapGetters } from "vuex";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CheckboxList from "@/view/content/forms/components/CheckboxList.vue";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin";
import moment from "moment/moment";

export default {
  name: "UsersScheduleList",
  mixins: [permissionMixin, formBuilderMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      isPDFloading: false,
      firstLoader: true,
      search: "",
      dialog: false,
      items: [],
      stores: [],
      missingDates: [],
      users: [],
      totalItems: 100,
      searchQuery: "",
      storeName: "",
      workDayDate: moment().format("YYYY-MM-DD"),
      filters: {
        workDay: "",
        storeId: null,
        users: []
      },
      options: {
        user_id: 1,
        descending: false,
        page: 1,
        itemsPerPage: 10,
        totalItems: 100
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "First Name", value: "first_name", sortable: false },
        { text: "Last Name", value: "last_name", sortable: false },
        { text: "Store", value: "store", sortable: false },
        { text: "Date", value: "work_day", sortable: false }
      ]
    };
  },
  components: {
    CheckboxList,
    SearchTools,
    FormDatepickerBase
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users Schedule", route: { name: "list-users-schedule" } },
      { title: "Users Schedule List" }
    ]);
    vm.getSelects();
  },
  computed: {
    ...mapGetters(["getUsersSchedule", "isLoadingUsersSchedule"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        workDay: this.filters.workDay,
        stores: this.filters.storeId
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    close() {
      let vm = this;
      vm.dialog = false;
    },
    save() {
      let vm = this;
      let storeId;
      let workDay;
      let employeesWithValidHCids;

      if (vm.filters.storeId === "" || vm.filters.storeId === null) {
        storeId = vm.stores[0].id;
      } else {
        storeId = vm.filters.storeId;
      }

      if (vm.filters.workDay !== null && vm.filters.workDay !== "") {
        workDay = vm.filters.workDay;
      } else {
        workDay = moment().format("YYYY-MM-DD");
      }
      employeesWithValidHCids = vm.users.map(user => {
        return user.id;
      });
      let payload = {
        users: vm.filters.users.filter(userId =>
          employeesWithValidHCids.includes(userId)
        ),
        store_id: storeId,
        work_day: workDay
      };
      this.$store
        .dispatch(SAVE_USERS_SCHEDULE, payload)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.$nextTick(function() {
            vm.close();
            vm.getUsersMissingSchedules();
            vm.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    missingDateSearch(storeId, missingDate) {
      let vm = this;
      vm.filters.workDay = missingDate;
      vm.filters.storeId = parseInt(storeId);
      vm.getUsersScheduleList();
      vm.dialog = true;
      vm.getDataFromApi();
    },
    doClear() {
      let vm = this;
      vm.storeName = vm.stores[0].name;
      vm.workDayDate = moment().format("YYYY-MM-DD");
      vm.searchQuery = "";
      vm.filters.storeId = "";
      vm.filters.workDay = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getPDF() {
      let vm = this;
      vm.isPDFloading = true;
      //copy current params to modify
      vm.isLoadingStoreDailyTasks = true;
      let datePDF =
        vm.filters.workDay != null && vm.filters.workDay != ""
          ? vm.filters.workDay
          : moment().format("MM-DD-YYYY");
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(USERS_SCHEDULE_PDF, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "store-tasks-" + vm.storeName + "-" + datePDF;
            link.click();
            link.remove();
            vm.dialogPDF = false;
            if (vm.isPageLoading == true || vm.isPDFloading == true) {
              vm.isPageLoading = false;
              vm.isPDFloading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_USERS_SCHEDULE, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            vm.createStoreWorkingDateStrings();
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getUsersScheduleList() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.workDay = vm.filters.workDay;
      params.storeId = vm.filters.storeId;
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_USERS_WITH_VALID_HC, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.users = data.data.items;
            vm.getUsersScheduleListFromDB();
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getUsersScheduleListFromDB() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.workDay = vm.filters.workDay;
      params.storeId = vm.filters.storeId;
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_USERS_SCHEDULE_LIST, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.filters.users = [];
            data.data.items.forEach(function(item) {
              vm.filters.users.push(item.id);
            });
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getUsersMissingSchedules() {
      let vm = this;
      let storesIds;

      storesIds = vm.stores.map(store => {
        return store.id;
      });

      this.$store
        .dispatch(FETCH_MISSING_SCHEDULE_DATES, storesIds)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.missingDates = data.data;
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getSelects() {
      let vm = this;
      let payload = {
        stores: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.stores = data.data.stores;
          vm.filters.storeId = data.data.stores[0].id;
          vm.getUsersMissingSchedules();
          vm.createStoreWorkingDateStrings();
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    createStoreWorkingDateStrings() {
      let vm = this;
      if (vm.filters.workDay !== null && vm.filters.workDay !== "") {
        vm.workDayDate = vm.filters.workDay;
      } else {
        vm.workDayDate = moment().format("YYYY-MM-DD");
      }
      vm.storeName = vm.stores.find(function(store) {
        return store.id === vm.filters.storeId;
      }).name;
    }
  }
};
</script>
